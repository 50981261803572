import { graphql } from "gatsby";
import React from "react";

import CategoriesMenu from "./../components/blog/categoriesMenu/categoriesMenu";
import SubscribeBlockBlog from "./../components/blog/subscribeBlockBlog/subscribeBlockBlog";
import Layout from "./../components/layout";
import Post from "./../components/post/post";
import Seo from "./../components/seo";
import Title from "./../components/title/title";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
const chunk = require(`lodash/chunk`)

const CategoryArchive = ({ data, location }) => {
  const bp = useBreakpoint();
  const category = data.wpCategory;
  const posts = data.wpCategory.posts.nodes;

  const [postsChunked, setPostsChunked] = React.useState([]);
  const [totalPostsPages, setTotalPages] = React.useState(0);
  const [showingPosts, setShowingPosts] = React.useState([]);
  const [showingPostsPage, setShowingPostsPage] = React.useState(0);
  const postsPerPage = 9;


  React.useEffect(() => {
    // split posts into chunks
    let postsChunkedIntoArchivePages = chunk(posts, postsPerPage);
    setPostsChunked(postsChunkedIntoArchivePages);
    setTotalPages(postsChunkedIntoArchivePages.length);
    if (postsChunkedIntoArchivePages?.length > 0) {
      setShowingPosts(postsChunkedIntoArchivePages[0]);
      setShowingPostsPage(1);
    }
  }, []);

  const addShowedPostsByPage = () => {
    let newShowingPosts = [...showingPosts, ...postsChunked[showingPostsPage] ?? []];
    setShowingPosts(newShowingPosts);
    setShowingPostsPage(showingPostsPage + 1);
  }

  return (
    <Layout>
      <Seo title={category.name} description={category?.seo?.metaDesc} pageSeo={category} />
      <div className="container">
      {bp?.lg && <SubscribeBlockBlog /> }
        <Title h1={true}>{category.name}</Title>
      </div>
      <CategoriesMenu />
      <div className="container">
        {(!!posts?.length && posts?.length > 0)
          ? <>
            {showingPosts?.length > 0 &&
              <>
                <div className={`row v7articlesWrapper`}>
                  {showingPosts?.map(post => {
                    return (
                      <div className="col-md-6 col-lg-4 mb-3" key={post.uri}>
                        <Post post={post} />
                      </div>
                    )
                  })}
                </div>
                {showingPostsPage < totalPostsPages && <button className={`exploreBtn d-block ml-auto mt-3`} onClick={() => { addShowedPostsByPage() }}>Explore more stories</button>}
              </>
            }
          </>
          : <p>
            No posts found. Add posts to your WordPress site and they'll
            appear here!
          </p>}

      </div>
      {!bp?.lg &&
        <div className="container">
          <SubscribeBlockBlog />
        </div>
      }
    </Layout>
  );
}

export default CategoryArchive

export const pageQuery = graphql`
  query WordPressCategoryPosts(
    $id: String!
  ) {
    wpCategory(id: {eq: $id}) {
      id
      name
      slug
      uri
      description
      posts {
        nodes {
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          readingTime {
            text
          }
          featuredImage {
            node {
              gatsbyImage(width: 600,quality: 80)
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData( quality: 80 )
                }
              }
            }
          }
          categories {
            nodes {
              name
              uri
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`
